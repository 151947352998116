import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Button from "components/common/atoms/Button";
import {
  validateEmail,
  validateMultiSelectField,
  validateSelectField,
  validateTextFieldAlpha,
  validateRequired,
  scrollToTop,
  validateDate,
  validateRadioField,
  validateVatNumber,
  validatePhoneNumber,
} from "components/common/services/ValidationService";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormContext } from "../../context/Context";
import * as CONST from "../../annotations/CoopAgreementConstants";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import ValidateData from "./ValidateData";
import CustomNotify from "components/common/atoms/CustomNotify";
import { COMPANY_MICROSERVICE } from "Constants";
import { MANAGE_COOPERATION_AGREEMENT } from "routes/RouteConstants";
import { ApiCall } from "components/common/services/ApiServices";
import * as ENDPOINTS from "../../../../../routes/ApiEndpoints";

export interface MandatoryFields {
  [key: string]: string[];
}

interface NavProps {
  validStatus: (value: { isValid: boolean; type: string }) => void;
  isLoading?: boolean;
}

interface ValidationData {
  coefficientTab: boolean;
  coefficientTabMinError: boolean;
  compositionTab: boolean;
}

const Navigation: React.FC<NavProps> = ({ validStatus, isLoading = false }) => {
  const { state, dispatch } = useFormContext();
  const userAuth = useSelector(selectAuth);
  const { companyId } = useParams<{ companyId: string }>();
  const { agreementId } = useParams<{ agreementId: string }>();
  const currentTab = state.tabDetails.find((tab) => tab.showStatus);
  const isBasicTab = currentTab && currentTab.id === "general";
  const isLastTab = currentTab && currentTab.id === "invoice";
  const isCoeffTab = currentTab && currentTab.id === "coefficients";
  const specialTab = currentTab && currentTab.id === "compositionData";
  const feeBased = state.general.coeffType === 1;
  const navigate = useNavigate();
  const validateMandatoryFields = (type: string) => {
    const activeTab = state.tabDetails.find((tab) => tab.showStatus);
    const activeTabId = activeTab?.id;

    const mandatoryFieldMapping: { [key: string]: string[] } =
      type === "draft"
        ? {
            general: ["company", "type"],
          }
        : {
            general: [
              "company",
              "startDate",
              "consultant",
              "officeNumber",
              "province",
              "type",
              "paritairBlue",
              "paritairWhite",
            ],
            contacts: ["fName", "lName", "email", "phNumber"],
            invoice: ["paymentTerm", "billingType"],
          };

    const errorsByTab: { [tabId: string]: { [fieldName: string]: string } } =
      {};
    state.tabDetails
      .slice(0, state.tabDetails.findIndex((tab) => tab.id === activeTabId) + 1)
      .forEach((tab) => {
        const tabId = tab.id;
        let tabFields = {};

        if (tabId === "general") {
          tabFields = state.general;
        } else if (tabId === "contacts") {
          tabFields = state.contacts;
        } else if (tabId === "invoice") {
          tabFields = state.invoice;
        }

        const validationRules: {
          [fieldName: string]: ((value: any) => string | null | undefined)[];
        } = {
          vat: [validateVatNumber],
          company: [validateRequired],
          startDate: [validateDate],
          consultant: [validateSelectField],
          officeNumber: [validateSelectField],
          province: [validateMultiSelectField],
          type: [validateRadioField],
          vatInclusive: [validateRadioField],
          // paritairBlue:
          //   state.general.paritairWhite.length > 0
          //     ? []
          //     : [validatePCEmployeeType],
          // paritairWhite:
          //   state.general.paritairBlue.length > 0
          //     ? []
          //     : [validatePCEmployeeType],
          // payment: [validateNumberField],
          paymentTerm: [validateSelectField],
          billingType: [validateRadioField],
          // invoiceType: [validateRadioField],
          // status: [validateRadioField],
          // corrections: [validateRadioField],
          fName: [validateRequired, validateTextFieldAlpha],
          lName: [validateRequired, validateTextFieldAlpha],
          email: [validateRequired, validateEmail],
          // phNumber: [validatePhoneNumber],
          // dob: [validateDate]
        };

        const mandatoryFields = (mandatoryFieldMapping[tabId] ||
          []) as string[];

        const errorsInTab: { [fieldName: string]: string } = {};
        for (const fieldName of mandatoryFields) {
          const fieldValidationRules = validationRules[fieldName];

          if (tabId === "contacts" && state.contacts.length > 0) {
            state.contacts.forEach((contact, index: number) => {
              const fieldValue = contact[fieldName as keyof typeof contact];
              const contactErrors: string[] = [];

              if (fieldValidationRules && contact.type !== 0) {
                for (const rule of fieldValidationRules) {
                  const validationError = rule(fieldValue as any);

                  if (validationError) {
                    contactErrors.push(validationError);
                    errorsInTab[fieldName] = validationError;
                    dispatch({
                      type: CONST.UPDATE_CONTACTS_FIELD_ERROR,
                      field: fieldName,
                      error: validationError,
                      index,
                    });
                    break;
                  } else {
                    dispatch({
                      type: CONST.UPDATE_CONTACTS_FIELD_ERROR,
                      field: fieldName,
                      error: "",
                      index,
                    });
                  }
                }
              }
            });
          }
          // else if (tabId === "general" && state.general.coeffType === 1 && state.general.feeBased.length > 0) {
          //   state.general.feeBased.forEach((feeBased, index) => {

          //     Object.entries(feeBased).forEach(([key, value]) => {
          //       if (key !== 'id' && (value === null || value === '')) {
          //         const errorMsg = `This field is required`;
          //         dispatch({
          //           type: CONST.UPDATE_FEEBASED_ERROR,
          //           fieldName: key,
          //           index,
          //           error: errorMsg,
          //         });

          //         errorsInTab[key] = errorMsg;
          //       }
          //     })
          //   });
          // }
          else {
            const fieldValue = tabFields[fieldName as keyof typeof tabFields];

            if (fieldValidationRules) {
              for (const rule of fieldValidationRules) {
                const validationError = rule(fieldValue);

                if (validationError) {
                  errorsInTab[fieldName] = validationError;
                  dispatch({
                    type: CONST.UPDATE_FIELD_ERROR,
                    fieldName: fieldName,
                    error: validationError,
                  });
                  break;
                } else {
                  dispatch({
                    type: CONST.UPDATE_FIELD_ERROR,
                    fieldName: fieldName,
                    error: "",
                  });
                }
              }
            }
          }
        }

        if (Object.keys(errorsInTab).length > 0) {
          errorsByTab[tabId] = errorsInTab;
          return false;
        }
      });

    updateTabsStatus(errorsByTab);
    if (Object.keys(errorsByTab).length > 0) {
      return false;
    }

    return true;
  };

  // Function to find an object by its 'id' property
  const findTabObjectById = (idToFind: string) => {
    return state.tabDetails.findIndex((tab) => tab.id === idToFind);
  };

  const updateTabsStatus = (errors: object) => {
    const keysToGet = [
      "general",
      "contacts",
      "coefficients",
      "compositionData",
      "invoice",
    ];
    const selectKeys = keysToGet
      .map((key) => {
        if (errors.hasOwnProperty(key)) {
          const tabIndex = findTabObjectById(key);
          if (tabIndex !== undefined && tabIndex !== null) {
            dispatch({ type: CONST.UPDATE_TAB_ERROR, tabIndex, error: true });
          }
          return tabIndex;
        }
        return null;
      })
      .filter((key) => key !== null);

    const tabIndexes = [0, 1, 2, 3, 4];
    tabIndexes.forEach((index) => {
      if (!selectKeys.includes(index)) {
        dispatch({
          type: CONST.UPDATE_TAB_ERROR,
          tabIndex: index,
          error: false,
        });
      }
    });
  };

  const handleNextStep = () => {
    if (isCoeffTab || specialTab) {
      const validation: ValidationData = ValidateData({
        state: state,
        dispatch: dispatch,
        userAuth: userAuth,
        submitType: "draft",
      });
      if (validation.coefficientTab) {
        validStatus({ isValid: false, type: "next" });
        scrollToTop();
      } else {
        validStatus({ isValid: true, type: "next" });
      }
    } else {
      validStatus({ isValid: true, type: "next" });
    }
    dispatch({ type: CONST.NEXT_STEP, feeBased: feeBased });
  };

  const handlePreviousStep = () => {
    dispatch({ type: CONST.PREVIOUS_STEP, feeBased: feeBased });
  };

  const handleDraft = () => {
    const update = state.tabDetails.map((tab, index) => ({
      ...tab,
      draft: true,
    }));
    dispatch({ type: CONST.UPDATE_TAB_DETAILS, tabDetails: update });
    const valid = validateMandatoryFields("draft");

    if (isCoeffTab || specialTab) {
      const validation: ValidationData = ValidateData({
        state: state,
        dispatch: dispatch,
        userAuth: userAuth,
        submitType: "draft",
      });
      dispatch({
        type: CONST.UPDATE_TAB_ERROR,
        tabIndex: 2,
        error: validation.coefficientTab,
      });
      if (validation.coefficientTab || !valid) {
        validStatus({ isValid: false, type: "draft" });
        scrollToTop();
      } else {
        validStatus({ isValid: true, type: "draft" });
      }
    } else if (!valid) {
      const special = currentTab?.id === "compositionData";
      const specialTabStatus =
        special &&
        ValidateData({
          state: state,
          dispatch: dispatch,
          userAuth: userAuth,
          submitType: "draft",
        });
      if ((specialTabStatus && specialTabStatus.coefficientTab) || !valid)
        validStatus({ isValid: false, type: "draft" });
      scrollToTop();
    } else {
      validStatus({ isValid: true, type: "draft" });
    }
  };

  const handleSubmit = () => {
    const update = state.tabDetails.map((tab, index) => ({
      ...tab,
      draft: false,
    }));
    const companyResponsiblePerson = state.contacts
      .filter((item: any) => item.type !== 0)
      .every((item: any) => !item?.allowSignature);
    if (companyResponsiblePerson) {
      dispatch({ type: CONST.UPDATE_TAB_ERROR, tabIndex: 1, error: true });
      CustomNotify({
        type: "error",
        message: t("Select at least one contact as a decision maker"),
      });
      return;
    }
    const validation: ValidationData = ValidateData({
      state: state,
      dispatch: dispatch,
      userAuth: userAuth,
      submitType: "submit",
    });
    dispatch({ type: CONST.UPDATE_TAB_DETAILS, tabDetails: update });
    const valid = validateMandatoryFields("submit");
    const errorStaus = validation.coefficientTab
      ? validation.coefficientTab
      : validation.coefficientTabMinError;
    dispatch({ type: CONST.UPDATE_TAB_ERROR, tabIndex: 2, error: errorStaus });
    dispatch({
      type: CONST.UPDATE_TAB_ERROR,
      tabIndex: 3,
      error: validation.compositionTab,
    });

    if (validation.coefficientTab || !valid) {
      validStatus({ isValid: false, type: "submit" });
      scrollToTop();
    } else {
      validStatus({ isValid: true, type: "submit" });
    }
  };

  const handleSendToBright = async () => {
    const data = {
      type: "toBright",
      agreementId,
      companyId,
    };
    const response = await ApiCall.service(
      ENDPOINTS.SEND_FOR_AM_APPROVAL,
      "POST",
      data,
      true,
      COMPANY_MICROSERVICE
    );

    if (response.status === 200) {
      CustomNotify({ type: "success", message: t(response.msg) });
      navigate(MANAGE_COOPERATION_AGREEMENT);
    }
  };

  return (
    <>
      <div className="row backPadding">
        <div className="col-md-3 align-self-center">
          {!isBasicTab && (
            <Button
              type="button"
              title={t("Back")}
              handleClick={handlePreviousStep}
              className="backBtn p-0 text-decoration-underline border-0"
            />
          )}
          {isBasicTab && (
            <Link
              to="/manage/cooperation-agreement"
              className="back-btn text-decoration-underline border-0"
            >
              {t("Back")}
            </Link>
          )}
        </div>
        <div className="col-md-9">
          <div className="d-flex align-items-center flex-row-reverse">
            {!isLoading ? (
              <Button
                type="submit"
                title={isLastTab ? t("Submit") : t("Next")}
                handleClick={isLastTab ? handleSubmit : handleNextStep}
                className="float-end form-button shadow-none "
              />
            ) : (
              <LoadingIcon
                iconType="bars"
                color="#e55496"
                className="mb-3 ms-3"
                height={36}
              />
            )}
            {!isLoading &&
              !isLastTab &&
              state.general.agreementStatus !== 1 && (
                <Button
                  type="submit"
                  title={t("Save as draft")}
                  handleClick={handleDraft}
                  className="float-end form-button marginRightPoint5 shadow-none"
                />
              )}
            {!isLoading &&
              isCoeffTab &&
              state.general.amApproveButton === 1 &&
              ["ADMIN", "AREA_MANAGER", "SUPER_ADMIN"].includes(
                userAuth.role
              ) && (
                <Button
                  type="submit"
                  title={t("Approve and send to bright")}
                  handleClick={handleSendToBright}
                  className="float-end form-button marginRightPoint5 shadow-none"
                />
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default translate(Navigation);
