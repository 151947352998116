import { useFormContext } from "components/CompanyCreation/Company/context/Context";
import Button from "components/common/atoms/Button";
import {
  validateSelectField,
  validateRequired,
  scrollToTop,
  validateLinkedinUrl,
  validateVatNumber,
  validatePhoneNumberCanBeNull,
  validateEmailCanBeNull,
} from "components/common/services/ValidationService";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import { t, translate } from "components/CentralDataMangement/translation/Translation";
import { ApiCall } from "components/common/services/ApiServices";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { RESGISTER_COMPANY } from "routes/ApiEndpoints";
import CustomNotify from "components/common/atoms/CustomNotify";
import GetQueryParams from "components/common/services/GetQueryParams";
import { getNextTabId } from "components/common/utlis/TabUtility";

export interface MandatoryFields {
  [key: string]: string[];
}

interface NavProps {
  validStatus: (value: { isValid: boolean; type: string }) => void;
  isLoading?: boolean;
}
const Navigation: React.FC<NavProps> = ({
  validStatus,
  isLoading = false,
}) => {
  const { state, dispatch } = useFormContext();

  const validateMandatoryFields = (type: string) => {
    const activeTab = state.tabDetails.find((tab) => tab.showStatus);
    const activeTabId = activeTab?.id;

    const mandatoryFieldMapping: { [key: string]: string[] } =
      type === "draft"
        ? {
          basic: ["companyName", "vat"],
        }
        : {
          basic: ["companyName", "officialName", "vat"],
          address: [
            'location',
            "street",
            "number",
            "zipCode",
            "country",
            "city",
          ],
          contacts: ["email", "phNumber", "linkedIn"],
        };

    const errorsByTab: { [tabId: string]: { [fieldName: string]: string } } =
      {};
    state.tabDetails
      .slice(0, state.tabDetails.findIndex((tab) => tab.id === activeTabId) + 1)
      .forEach((tab) => {
        const tabId = tab.id;
        let tabFields = {};

        if (tabId === "basic") {
          tabFields = state.basic;
        } else if (tabId === "address") {
          tabFields = state.address;
        } else if (tabId === "contacts") {
          tabFields = state.contacts;
        }

        const validationRules: {
          [fieldName: string]: ((value: any) => string | null | undefined)[];
        } = {
          vat: [
            validateVatNumber
          ],
          companyName: [validateRequired],
          officialName: [validateRequired],
          location: [validateRequired],
          street: [validateRequired],
          number: [validateRequired],
          zipCode: [validateRequired],
          city: [validateSelectField],
          country: [validateSelectField],
          // fName: [validateRequired,validateTextFieldAlpha],
          // lName: [validateRequired,validateTextFieldAlpha],
          email: [validateEmailCanBeNull],
          // phNumber: [validatePhoneNumberCanBeNull],
          linkedIn: [validateLinkedinUrl]
        };

        const mandatoryFields = (mandatoryFieldMapping[tabId] ||
          []) as string[];

        const errorsInTab: { [fieldName: string]: string } = {};
        for (const fieldName of mandatoryFields) {
          const fieldValidationRules = validationRules[fieldName];

          if (tabId === "contacts" && state.contacts.length > 0) {
            state.contacts.forEach((contact, index: number) => {
              const fieldValue = contact[fieldName as keyof typeof contact];
              const contactErrors: string[] = [];

              if (fieldValidationRules) {
                for (const rule of fieldValidationRules) {
                  const validationError = rule(fieldValue as any);

                  if (validationError) {
                    contactErrors.push(validationError);
                    errorsInTab[fieldName] = validationError;
                    dispatch({ type: "UPDATE_CONTACTS_FIELD_ERROR", field: fieldName, error: validationError, index });
                    break;
                  } else {
                    dispatch({ type: "UPDATE_CONTACTS_FIELD_ERROR", field: fieldName, error: "", index });
                  }
                }
              }
            });
          } else {
            const fieldValue = tabFields[fieldName as keyof typeof tabFields];

            if (fieldValidationRules) {
              for (const rule of fieldValidationRules) {
                const validationError = rule(fieldValue);

                if (validationError) {
                  errorsInTab[fieldName] = validationError;
                  dispatch({ type: "UPDATE_FIELD_ERROR", fieldName: fieldName, error: validationError });
                  break;
                } else {
                  dispatch({ type: "UPDATE_FIELD_ERROR", fieldName: fieldName, error: "" });
                }
              }
            }
          }
        }

        if (Object.keys(errorsInTab).length > 0) {
          errorsByTab[tabId] = errorsInTab;
          return false;
        }
      });
    updateTabsStatus(errorsByTab);
    if (Object.keys(errorsByTab).length > 0) {
      return false;
    }

    return true;
  };

  // Function to find an object by its 'id' property
  const findTabObjectById = (idToFind: string) => {
    return state.tabDetails.findIndex((tab) => tab.id === idToFind);
  };

  const updateTabsStatus = (errors: object) => {
    const keysToGet = ["basic", "address", "contacts"];
    const selectKeys = keysToGet
      .map((key) => {
        if (errors.hasOwnProperty(key)) {
          const tabIndex = findTabObjectById(key);
          if (tabIndex !== undefined && tabIndex !== null) {
            dispatch({ type: "UPDATE_TAB_ERROR", tabIndex, error: true });
          }
          return tabIndex;
        }
        return null;
      })
      .filter((key) => key !== null);

    const tabIndexes = [0, 1, 2];
    tabIndexes.forEach((index) => {
      if (!selectKeys.includes(index)) {
        dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: index, error: false });
      }
    });
  };


  const handleNextStep = () => {
    dispatch({ type: "NEXT_STEP" });
    handleSubmitFormData("next");
  };

  const handlePreviousStep = () => {
    dispatch({ type: "PREVIOUS_STEP" });
  };

  const handleDraft = () => {
    const update = state.tabDetails.map((tab, index) => ({
      ...tab,
      draft: true
    }));
    dispatch({ type: "UPDATE_TAB_DETAILS", tabDetails: update });
    const valid = validateMandatoryFields("draft");
    if (!valid) {
      validStatus({ isValid: false, type: "draft" });
      scrollToTop();
    } else {
      validStatus({ isValid: true, type: "draft" });
      handleSubmitFormData("draft");
    }
  };

  const handleSubmit = () => {
    const update = state.tabDetails.map((tab, index) => ({
      ...tab,
      draft: false
    }));
    dispatch({ type: "UPDATE_TAB_DETAILS", tabDetails: update });
    const valid = validateMandatoryFields("submit");

    if (!valid) {
      validStatus({ isValid: false, type: "submit" });
      scrollToTop();
    } else {
      validStatus({ isValid: true, type: "submit" });
    }
  };

  const { companyId } = useParams<{ companyId: string }>();
  const userAuth = useSelector(selectAuth);
  const navigate = useNavigate();
  const queryParams = GetQueryParams();

  const checkForDuplicates = () => {
    const phoneSet = new Set();
    let hasDuplicate = "";

    state.contacts.forEach((contact, index) => {
      if (contact.phNumber?.trim() !== "") {
        if (phoneSet.has(contact.phNumber)) {
          hasDuplicate = "phone";
        } else {
          phoneSet.add(contact.phNumber);
        }
      }
    });

    return hasDuplicate;
  };


  const handleSubmitFormData = async (submitType: string) => {
    let duplicateNotify = true;
    let dupliMes = null;
    const currentId = getNextTabId(state.tabDetails);
    const formData = {
      basic: state.basic,
      address: state.address,
      contacts: state.contacts,
      userId: userAuth.userId,
    };
    // if (currentId === 'documents') {
    //   const duplicates = checkForDuplicates();
    //   dupliMes =
    //     duplicates !== "" && duplicates === "phone"
    //       ? t("Duplicate phone number")
    //       : null;
    //   if (duplicateNotify && dupliMes) {
    //     duplicateNotify = false;
    //     CustomNotify({ type: "warning", message: "Duplicate phone number" });
    //   }
    // }
    if (state.basic.vat !== "" && state.basic.companyName !== "") {
      const url = !companyId
        ? RESGISTER_COMPANY
        : `${RESGISTER_COMPANY}/${companyId}`;
      const response = await ApiCall.service(
        url,
        "POST",
        formData,
        false,
        "company-creation"
      );
      if (response.status === 200) {
        if (submitType === "draft") {
          CustomNotify({
            type: "success",
            message: t("Draft saved successfully") + "!",
          });
          navigate(queryParams.get('destination') ?? "/manage-companies");
        } else if (queryParams.get('destination')) {
          navigate(`/company/${response.companyId}?tabId=${currentId}&destination=/company-contacts`)
        } else {
          navigate(`/company/${response.companyId}?tabId=${currentId}`);
        }
      } else if (response.status === 400) {
        CustomNotify({ type: "error", message: t(response.msg.vat_number) });
        dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
        state.fieldError.vat = response.msg.vat_number;
      } else {
        if (response?.emails) {
          state.contacts.forEach((contact, index) => {
            if (response.emails.includes(contact.email)) {
              dispatch({
                type: "UPDATE_CONTACTS_FIELD_ERROR",
                index: index,
                field: "email",
                error: t("Email already exists with a different role"),
              });
            }
          });

          dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 2, error: true });
        } else {
          dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 3, error: true });
          CustomNotify({
            type: "error",
            message: t("Some error occurred"),
          });
        }
      }
    }
  }

  const currentTab = state.tabDetails.find((tab) => tab.showStatus);
  const isBasicTab = currentTab && currentTab.id === "basic";
  const islastTab = currentTab && currentTab.id === "documents";

  const companyStatus = state.basic.companyStatus;

  return (
    <>
        <div className="row backPadding">
          {!isBasicTab && (
            <div className="col-md-6 align-self-center">
              <Button
                type="button"
                title={t("Back")}
                handleClick={handlePreviousStep}
                className="backBtn p-0 text-decoration-underline border-0"
              />
            </div>
          )}
          <div className={isBasicTab ? "col-md-12" : "col-md-6"}>
            {!isLoading ? (
              <Button
                type="submit"
                title={islastTab ? t("Submit") : t("Next")}
                handleClick={islastTab ? handleSubmit : handleNextStep}
                className="float-end form-button shadow-none"
              />
            ) : (
              <LoadingIcon
                iconType="bars"
                color="#e55496"
                className="ms-auto mb-3"
                height={"3vw"}
                width={"3vw"}
              />
            )}
            {companyStatus !== 1 && !islastTab && (
              <Button
                type="submit"
                title={t("Save as draft")}
                handleClick={handleDraft}
                className="float-end form-button marginRightPoint5 shadow-none"
              />
            )}
          </div>
        </div>
    </>
  );
};

export default translate(Navigation);